import React, { Fragment } from "react"
import { observer, inject } from "mobx-react"

import Provider from "../store/provider"

const AddToFav = inject(`store`)(
  observer(({ store, vendor }) => {
    const onClick = (e) => {
      e && e.preventDefault()

      store.AddFav(vendor.path, vendor)

      e.target.blur()
    }

    let btnState = { disabled: false, text: "Add To Favorites" }

    const cleanPath = vendor.path.replace(/\/$/, "");

    if (store.Favorites.hasOwnProperty(cleanPath)) {
      btnState.disabled = true;
      btnState.text = "In favorites"
    }

    return <Fragment>
      <a
        href=""
        class={ `btn btn-danger ${btnState.disabled ? "disabled" : ""}` }
        onClick={onClick}
        disabled={btnState.disabled}
      >&#10084; { btnState.text }</a>
      { btnState.disabled && <a href="/favorites" class="btn btn-warning" style={{marginLeft: "14px"}}>View Favorites</a> }
    </Fragment>
  })
)

export default function(props) { return <Provider><AddToFav {...props} /></Provider> }
