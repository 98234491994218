import React, { Component } from 'react';
import Img from 'gatsby-image'
import { graphql } from 'gatsby';
import Slider from "react-slick";
import Lightbox from 'react-image-lightbox';

import '../css/show.scss'
import '../css/slick-slider-n-lightbox.scss'

import Layout from '../layouts/home'

import FacebookIcon from '../svgs/facebook.svg';
import InstagramIcon from '../svgs/instagram.svg';
import YoutubeIcon from '../svgs/youtube.svg';

import ReviewForm from '../components/reviewForm'
import MessageForm from '../components/messageForm'
import SEO from '../components/seo'
import AddToFav from '../components/addToFav'

import { keywords } from '../components/meta';


class ShowTemplate extends Component {
  constructor(props){
    super(props);

    this.state = {
      images: props.data.allFile ? props.data.allFile.edges.map(e => e.node.childImageSharp.fluid.originalImg) : [],
      currentIndex: 0,
      isImageModalOpen: false
    }
  }

  componentDidMount() {
    if (typeof(addthis) === 'undefined') {
      const script = document.createElement("script");
      script.src = "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5c4463175b55af99";
      script.async = true;
      document.body.appendChild(script);
    } else {
      window.addthis.layers.refresh();
    }

    // if (typeof(FB) === 'undefined') {
    //   (function(d, s, id) {
    //     var js, fjs = d.getElementsByTagName(s)[0];
    //     if (d.getElementById(id)) return;
    //     js = d.createElement(s); js.id = id;
    //     js.src = 'https://connect.facebook.net/en_GB/sdk.js#xfbml=1&version=v3.2';
    //     fjs.parentNode.insertBefore(js, fjs);
    //   }(document, 'script', 'facebook-jssdk'));
    // } else {
    //   window.FB.XFBML.parse()
    // }
  }

  handleClickImage = (e, imageIndex) => {
    e && e.preventDefault();

    this.setState({
      isImageModalOpen: true,
      currentIndex: imageIndex,
    })
   }

   handleCloseModal = (e) => {
    e && e.preventDefault();

    this.setState({
      isImageModalOpen: false
    })
   }

  vendorImages() {
    const settings = {
      slidesToShow: 3,
      slidesToScroll: 1,
      dots: true,
      infinite: false,
      speed: 500,
      responsive: [
        {
          breakpoint: 980, // tablet breakpoint
          settings: {
              slidesToShow: 2,
              slidesToScroll: 2
          }
        },
        {
          breakpoint: 480, // mobile breakpoint
          settings: {
              slidesToShow: 1,
              slidesToScroll: 1
          }
        }
      ]
    };
    const sliderContent = this.props.data.allFile && this.props.data.allFile.edges.map((e, i) => {
      return <div key={`slider-item-${i}`} onClick={ evnt => this.handleClickImage(evnt, i)}>
        <Img
        fluid={e.node.childImageSharp.fluid}
        class="img-fluid"
        />
      </div>
    })

    return <Slider {...settings}>
      { sliderContent }
    </Slider>
  }

  tags() {
    const { tags } = this.props.data.markdownRemark.frontmatter;
    return tags.map((tag, i) => <div class="col-md-4" key={`tag-${i}`}>
        <label class="custom-checkbox">
          <span class="ti-check-box"></span>
          <span class="custom-control-description">{ tag }</span>
        </label>
      </div>
    )
  }

  renderInTheNews() {
    const { news_media } = this.props.data.markdownRemark.frontmatter;
    if (news_media.length) {
      const news = news_media.map((n, i) => <div key={`news-${i}`}>
          <a href={n.link} target="_blank" rel="nofollow noopener noreferrer">{n.title}</a>
        </div>
      )
      return <div><hr /><h6>In the News:</h6>
        <div>{ news }</div>
      </div>
    }
  }

  blogs() {
    const { from_the_blogs } = this.props.data.markdownRemark.frontmatter;
    if (from_the_blogs.length) {
      const blogs = from_the_blogs.map((b, i) => <div key={`blogs-${i}`}>
        <a href={b.link} target="_blank" rel="nofollow noopener noreferrer">{b.title}</a>
        </div>
      )
      return <div><hr /><h6>From the Blogs:</h6>
        <div>{ blogs }</div>
      </div>
    }
  }

  description() {
    const { markdownRemark } = this.props.data;

    return <div class="booking-checkbox_wrap">
      <div class="booking-checkbox" dangerouslySetInnerHTML={{ __html: markdownRemark.html }}>
      </div>
      <hr />
      <div class="row">
        { this.tags() }
      </div>
      <hr />
      <div class="share-this">
        <div class="share-this--title">SHARE: </div>
        <div class="addthis_inline_share_toolbox share-this--icons"></div>
        {/* <div class="fb-like share-this--icon"
          data-href={window.location.href.split('?')[0]}
          data-layout="button_count"
          data-action="like"
          data-size="large"
          data-show-faces="false"
          data-share="true">
        </div> */}
      </div>
      { (markdownRemark.frontmatter.youtube_media.length > 0) && <>
          <hr />
          <iframe title="youtube-embed" type="text/html" width="100%" height="390" src={ markdownRemark.frontmatter.youtube_media[0] } frameBorder="0" />
        </> }
      { this.renderInTheNews() }
      { this.blogs() }
    </div>
  }

  packages() {
    const { packages } = this.props.data.markdownRemark.frontmatter;
    if (packages.length) {
      const pkgs = packages.map((p, i) => <div key={`pkg-${i}`}>
        <a href={p.link} target="_blank" class="offer-link" rel="nofollow noopener noreferrer">
          <img src={p.image} />
          <div class="offer-label">{p.title}</div>
        </a>
      </div>)
      return <div class="section-book section-offers">
        <h6>Offers &amp; Packages</h6>
        <div>{ pkgs }</div>
      </div>
    }
  }

  sidebar() {
    const { profileImage, see_all_packages_link, ph, google_maps_embed, mail, logo, title, location, website, facebook, instagram, youtube, rating, path } = this.props.data.markdownRemark.frontmatter;
    return <div class="follow">
      <div class="follow-img">
        <div style={{ backgroundImage: `url(${profileImage.childImageSharp.fixed.src})` }} class="img-bg" />
        <Img fixed={ logo.childImageSharp.fixed } style={{ width: 100, height: 100 }} className="img-logo" />
        <div>
          { <div class="badge badge-pill badge-primary">{ rating ? `Rated ${rating} out of 5` : 'Not Rated' }</div> }
        </div>
        <h6>{ title }</h6>
        <div class="follow-item">{ location }</div>
        <a class="follow-item-link" href={website} target="_blank" rel="nofollow noopener noreferrer">{ website }</a>
        <div class="follow-item">E: <span>{ mail }</span></div>
        <div class="follow-item">PH: <span>{ ph }</span></div>
        { see_all_packages_link && <a rel="nofollow noopener noreferrer" class="follow-item-link" href={see_all_packages_link} target="_blank">Pricing &amp; Packages</a> }
      </div>
      <div class="section-info text-center">
        <p>
          { instagram && <a href={instagram} rel="nofollow" class="vendor-social-icon insta" target="blank">
              <InstagramIcon />
            </a>
          }
          { facebook && <a href={facebook} rel="nofollow" class="vendor-social-icon fb" target="blank">
              <FacebookIcon />
            </a>
          }
          { youtube &&  <a href={youtube} rel="nofollow" class="vendor-social-icon yt" target="blank">
              <YoutubeIcon />
            </a>
          }
        </p>
      </div>
      <div class="section-book">
        {/* <a class="btn btn-outline-dark btn-block" href="#">
          Request A Quote
        </a> */}
        <MessageForm serviceTitle={title} servicePath={path} />
      </div>
      { google_maps_embed && <div class="section-book">
        <h6>Location on the map:</h6>
        <iframe
          title="google-maps-embed"
          src={google_maps_embed}
          width="100%" height="450" frameBorder="0">
        </iframe>
      </div>
      }
      { this.packages() }
    </div>
  }

  reviews() {
    const { reviews, title, path } = this.props.data.markdownRemark.frontmatter;

    return <div class="booking-checkbox_wrap mt-4">
      <h5 id="review">Recommendations and Reviews</h5>
      <hr />
      {
        reviews.map((r, i) => <div class="customer-review_wrap" key={`reviews-${i}`}>
          <div class="customer-content-wrap">
            <div class="customer-content">
              <div>
                <b class="customer-rater">{ r.user_fullname }</b>
                {
                  r.rating && <div class="customer-rating">
                    <span>{r.rating}</span><span>★</span>
                  </div>
                }
                <p class="customer-text">{ r.message }</p>
              </div>
            </div>
          </div>
        </div>
        )
      }
      <ReviewForm serviceTitle={title} servicePath={path} />
    </div>
  }

  categories() {
    const { categories } = this.props.data.markdownRemark.frontmatter;
    return categories.map(c => <a href={`/services/${c}`} key={`category-${c}`}>{ c.titleize() }</a>).reduce((prev, curr) => [prev, ' / ', curr])
  }

  render() {
    const { markdownRemark } = this.props.data;
    const { categories, location, title, profileImage, path } = markdownRemark.frontmatter;
    const { currentIndex, isImageModalOpen, images } = this.state;

    const vendorObject = {
      path: `services/${path}`,
      title,
      profileImageSrc: profileImage.childImageSharp.fixed.src,
      location,
      categories
    }

    const categoriesTitle = categories.join(', ').titleize();

    const catKeywords = categories.map(c => {
      return keywords[c].join(', ')
    }).join(', ')

    return <Layout>
      <SEO
        title={`${title}`}
        titleTemplate={`%s - ${categoriesTitle} in ${location}`}
        osType='place'
        keywords={[
          title,
          `photos of ${title}`,
          `reviews for ${title}`,
          `contact ${title}`,
          catKeywords,
          location
        ]}
        description={`Contact ${title} in ${location} on CeremonyNepal. Browse info, photos, locations, contact info, reviews, rating and offers.`}
        ogDescription={`${categoriesTitle} in ${location} - Browse info, photos, locations, contact info, reviews, rating and offers.`}
        meta={[
          {
            property: 'og:url',
            content: `http://www.ceremonynepal.com/services/${path}`
          },
          {
            property: 'og:image',
            content: `http://www.ceremonynepal.com${profileImage.childImageSharp.fixed.src}`
          }
        ]}
      />
      { this.vendorImages() }
      <section class="reserve-block">
        <div class="container">
          <div class="row">
            <div class="col-md-6">
              <h1 title={title}>{ title }</h1>
              <p class="reserve-categories">
                In { this.categories() }
              </p>
            </div>
            <div class="col-md-6">
              <div class="reserve-seat-block">
                <div class="review-btn">
                  <a href="#review" class="btn btn-outline-dark">VIEW REVIEWS</a>
                </div>
                <div class="reserve-btn">
                  <AddToFav vendor={vendorObject} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="light-bg booking-details_wrap">
        <div class="container">
          <div class="row">
            <div class="col-md-8 responsive-wrap">
              { this.description() }
              { this.reviews() }
            </div>
            <div class="col-md-4 responsive-wrap">
              { this.sidebar() }
            </div>
          </div>
        </div>
      </section>
      { isImageModalOpen &&
         <Lightbox
            mainSrc={images[currentIndex]}
            nextSrc={images[(currentIndex + 1) % images.length]}
            prevSrc={images[(currentIndex + images.length - 1) % images.length]}
            onCloseRequest={this.handleCloseModal}
            reactModalStyle={{ overlay: { zIndex: 1040 }}}
            onMovePrevRequest={() =>
              this.setState({
                currentIndex: (currentIndex + images.length - 1) % images.length,
              })
            }
            onMoveNextRequest={() =>
              this.setState({
                currentIndex: (currentIndex + 1) % images.length,
              })
            }
          />
      }
    </Layout>;
  }
}

export default ShowTemplate

export const query = graphql`
  query ShowByPath($ContentPath: String, $ImagesPath: String) {
    allFile(filter: { sourceInstanceName: {eq: "content"}, extension: {regex: "/(jpg)|(jpeg)|(png)/"}, relativeDirectory: {eq: $ImagesPath} }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 720, maxHeight: 480, quality: 80  , cropFocus: ATTENTION) {
              originalImg
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    markdownRemark(frontmatter: { path: { eq: $ContentPath} }) {
      html
      frontmatter {
        path
        title
        rating
        location
        facebook
        instagram
        youtube
        website
        tags
        categories
        ph
        google_maps_embed
        youtube_media
        news_media {
          link
          title
          image
        }
        packages {
          link
          title
          image
        }
        see_all_packages_link
        from_the_blogs {
          link
          title
          image
        }
        mail
        reviews {
          user_fullname
          rating
          message
        }
        profileImage {
          childImageSharp {
            fixed(width: 420, height: 340) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        logo {
          childImageSharp {
            fixed(width: 320, height: 320) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`
