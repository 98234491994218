const meta = {
  "photographers": "Find the best Wedding Photographers and Videographers in Nepal on CeremonyNepal. Find info, research and contact photo and video professionals on CeremonyNepal, featuring reviews, portfolio, locations, ratings and offers",

  "videographers": "Find the best Wedding Videographers and Cinematographers in Nepal on CeremonyNepal. Find info, research and contact video professionals on CeremonyNepal, featuring reviews, portfolio, locations, rating and offers.",

  "hair-and-beauty": "Find the best Beauty Artists, Hair Salons and Bridal Makeup Artists in Nepal on CeremonyNepal. Find info, research and contact beauty professionals on CeremonyNepal, featuring reviews, locations, rating and offers.",

  "boutiques": "Find the best Boutiques and Bridal Wears in Nepal on CeremonyNepal. Find info, research and contact dress professionals on CeremonyNepal, featuring reviews, portfolio, locations, rating and offers.",

  "florists": "Find the best Florists in Nepal on CeremonyNepal. Find info, research and contact flower professionals on CeremonyNepal, featuring reviews, locations, rating and offers.",

  "cakes-and-bakeries": "Find the best Bakers and Cakes in Nepal on CeremonyNepal. Find info, research and contact cake professionals on CeremonyNepal, featuring reviews, photos, locations, rating and offers.",

  "venues": "Find the best Events and Wedding Venues in Nepal on CeremonyNepal. Find info, research and contact venues on CeremonyNepal, featuring reviews, photos, locations, rating and offers.",

  "event-planners": "Find the best Event and Wedding Planners in Nepal. Find info, research and contact event and wedding professionals on CeremonyNepal, featuring reviews, locations, rating and offers.",

  "travel-experts": "Find the best Travel Experts and Agencies in Nepal. Find info, research and contact travel professionals on CeremonyNepal, featuring reviews, locations, rating, offers and packages."
}

export const keywords = {
  "photographers": ["photographers", "wedding photographers", "wedding videographers"],
  "videographers": ["videographers", "wedding videographers", "cinematographers"],
  "hair-and-beauty": ["hair and makeup", "beauty salons", "makeup artists", "bridal makeup"],
  "boutiques": ["boutiques", "wedding dresses", "bridal wears"],
  "florists": ["florists"],
  "cakes-and-bakeries": ["bakers", "cakes"],
  "venues": ["wedding venues", "event venues", "catering and decor", "parties"],
  "event-planners": ["event planners", "wedding planners", "event management", "caterings"],
  "travel-experts": ["travel agents", "travel agencies", "travel packages", "honeymoon bookings"]
}

export default meta;
