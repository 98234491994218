import React from 'react';
import { Col, Button, Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap';
import Axios from 'axios';

import { encode } from  '../lib'

class MessageForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };
  }

  toggle = (e) => {
    e.preventDefault();
    this.setState({
      modal: !this.state.modal
    }, () => {
      this.refs.messageModal.style.display = this.state.modal ? 'block' : 'none';
    });
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    const payload = {
      "form-name": "service_message",
      name: form.name.value,
      email: form.email.value,
      subject: form.subject.value,
      message: form.message.value,
      phone: form.phone.value,
      service: this.props.servicePath,
    }

    Axios.post("/",
      encode(payload),
      { headers: { "Content-Type": "application/x-www-form-urlencoded" }},
    )
    .then(() => {
      alert("We have received your message. We will get back to you as soon as possible.\nThank you.")
      form.reset();
      this.refs.messageModal.style.display = 'none'
      this.setState({ modal: false })
    })
    .catch(() => {
      alert("Oops! Something went wrong.\nPlease try again later.")
    });
  };

  render() {
    return (
      <div>
        <a class="btn btn-outline-dark btn-block btn-msg" href="" onClick={this.toggle}>
          Message
        </a>

        <div class="modal" tabIndex="-1" role="dialog" ref="messageModal">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <Form
                name="service_message"
                method="post"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                action="/thanks"
                onSubmit={this.handleSubmit}
              >
                <ModalHeader toggle={this.toggle}>Messaging {this.props.serviceTitle}</ModalHeader>
                <ModalBody className="text-left">
                  <FormGroup row>
                    <Label sm={3} for="name">Name*</Label>
                    <Col sm={9}>
                      <input type="hidden" name="form-name" value="service_message" />
                      <Input type="text" name="name" id="name" required />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} for="email">Email*</Label>
                    <Col sm={9}>
                      <Input type="email" name="email" id="email" required />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} for="phone">Phone</Label>
                    <Col sm={9}>
                      <Input type="text" name="phone" id="phone" />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label sm={3} for="subject">Subject</Label>
                    <Col sm={9}>
                      <Input type="select" name="subject" id="subject" defaultValue="general">
                        <option value="general">General Enquiry</option>
                        <option value="booking">Bookings</option>
                        <option value="pricing">Pricing</option>
                        <option value="availability">Availability</option>
                        <option value="other">Other</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Message*</Label>
                    <Input type="textarea" name="message" id="message" required rows="4" />
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <Button type="submit" color="danger">Send</Button>
                  {' '}
                  <Button outline color="dark" onClick={this.toggle}>Cancel</Button>
                </ModalFooter>
              </Form>
            </div>
          </div>
        </div>
        { this.state.modal && <div class="modal-backdrop fade show"></div> }
      </div>
    );
  }
}

export default MessageForm;
